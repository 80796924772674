<template>
  <div 
    class="benefits-container"
    :class="{ teaser }"
  >
    <div 
      v-if="full"
      class="benefits-view"
    >
      <div 
        ref="display-container"
        class="display-container scrollable pt-4"
        :class="{ 'd-flex scrollable x smooth-scroll': teaser }"
      >
        <!-- <header
          v-if="!teaser"
          class="text-overline pa-3 pb-0 secondary--text text-center"
        >
          Benefícios Exclusivos:
        </header>
        <v-divider class="underline mb-6 mx-auto" /> -->
        <benefit-card 
          v-show="!teaser"
          v-for="benefit in requests"
          :key="benefit.key"
          :data="benefit"
          :loading="loading"
          :disabled="disabled||benefit.expired"
          class="mb-4 mx-5"
          @open="select"
        />

        <!-- <header
          v-if="!teaser"
          class="text-overline pa-3 pb-0 secondary--text text-center"
        >
          Destaques:
        </header>
        <v-divider class="underline mb-6 mx-auto" />
        <div
          v-for="(item, i) in display"
          :key="'display-item-'+i"
          class="benefits-display-item flex-shrink-0 d-inline-flex flex-column justify-center align-center"
          @click="open"
        >
          <v-img
            :src="controller.imgs[i]"
            contain
            eager
            max-width="50%"
            max-height="40%"
            class="rounded"
            @error="onImgError('display', i)"
          />
          <v-chip
            label
            outlined
            class="label text-overline grey--text mt-4"
            :class="[dark ? 'text--lighten-0' : 'text--lighten-2']"
          >
            {{ item.text }}
          </v-chip>
        </div>

        <p 
          v-if="!teaser"
          class="cta secondary--text text--lighten-1 text-h6 text-center py-4 mb-0" 
          v-html="cta" 
        /> -->
      </div> 

      <!-- <div 
        v-if="!teaser"
        class="benefits-footer text-center pa-4 pt-8 pb-16"
      >
        <img
          src="/img/confetti.svg"
          class="confetti"
        />
      </div>
      <v-btn
        v-if="!teaser"
        x-large
        color="secondary"
        class="benefits-footer-btn"
        @click="open"
      >
        Veja todos os benefícios
      </v-btn>
    </div>
    <div 
      v-else
      ref="available-container"
      class="benefits-basic scrollable pt-2"
      :class="{ 'd-flex scrollable x smooth-scroll': teaser }"
    >
      <benefit-card 
        v-for="benefit in data.available"
        :key="benefit.key"
        :data="benefit"
        :dense="teaser"
        :loading="loading"
        :disabled="disabled"
        class="benefits-basic-item flex-shrink-0 ma-4"
        :class="{ 'my-0': teaser }"
        @open="select"
      />
      <div 
        v-if="!teaser&&data.expired.length>0"
        class="benefits-expired"
      >
        <v-subheader 
          class="text-overline px-4 mb-2 mt-8 grey--text"
        >
          Benefícios Encerrados:
        </v-subheader>
        <benefit-card 
          v-for="benefit in data.expired"
          :key="benefit.key"
          :data="benefit"
          :disabled="disabled||benefit.expired||benefit.disabled"
          class="mx-4 mb-4"
        />
      </div> -->
    </div> 
  </div>
</template>

<style scoped>

.benefits-container {
  position: relative;
}
.benefits-view {
  max-height: 100%;
}
.benefits-view .underline {
  border-color: var(--city-400) !important;
  max-width: 25%;
}
.benefits-display-item {
  width: 50vw;
  height: 40vw;
}
.benefits-display-item .label {
  font-size: .65rem !important;
}

.benefits-view .scrollable:not(.teaser .benefits-view .scrollable) {
  max-height: calc(100vh - 56px);
  padding-bottom: 48px !important;
}
.benefits-basic:not(.teaser .benefits-basic) {
  max-height: calc(100vh - 56px);
  padding-bottom: 24px !important;
}
.benefits-basic-item {
  width: calc(100% - 32px);
}

.benefits-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(0deg, rgba(38,39,44,.9) 75%, rgba(38,39,44,0) 100%);
  pointer-events: none;
}
.benefits-footer-btn {
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
}

.benefits-view .cta {
  line-height: 1.75rem !important;
}
.benefits-footer .confetti {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  opacity: .4;
  transform: scaleY(-1);
}

</style>

<script>
import { mdiCheck, mdiChevronRight, mdiHelpCircle } from "@mdi/js";

import { Browser } from '@capacitor/browser';

export default {
  props: {
    data: {
      type: [Array, Object],
      default: () => {
        return {}
      },
    },
    cta: {
      type: String,
      default: 'Aproveite <span class="primary--text">+200 benefícios</span> <br> exclusivos para Parceiros!',
    },
    url: {
      type: String,
      default: null,
    },
    dark: {
      type: Boolean,
      default: true,
    },
    full: {
      type: Boolean,
      default: false,
    },
    teaser: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    icons: {
      help: mdiHelpCircle,
      arrow: mdiChevronRight,
      check: mdiCheck
    },
    tab: 0,
    tabs: {
      'display': 'Destaques',
      'requests': 'Cashback',
    },
    controller: {
      imgs: [],
      teaser: {
        playing: false,
        scroll: 0,
        max: null,
        interval: 5000,
        timer: null
      }
    },
    appURL: process.env.VUE_APP_ROOT
  }),

  computed: {
    requests () {
      const requests = _.orderBy(_.filter(this.data, item => _.has(item, 'key')), [i => i.expired ? 0 : 1], ['desc']);
      return requests;
    },
    display () {
      return this.teaser ? _.reject(this.data, ['teaser', false]) : _.reject(this.data, item => _.has(item, 'key'));
    }
  },

  watch: {
    display: {
      immediate: true,
      deep: true,
      handler (data) {
        if (this.teaser) {
          const controller = this.controller.teaser;
          controller.timer = setTimeout(($) => {
            $.playTeaser();
          }, 5000, this);
          data = _.reject(_.clone(data), ['teaser', false]);
        }
        this.controller.imgs = _.map(data, 'logo')
      }
    }
  },
  
  methods: {
    playTeaser (b=true) {
      const controller = this.controller.teaser;
      const target = this.full ? 'display' : 'available';
      const items = this.data[target];
      const container = _.has(this.$refs, target+'-container') ? this.$refs[target+'-container'] : null;
      if (b) {
        if (controller.playing) return;
        if (!!container) {
          const width = container.clientWidth;
          controller.max = (this.full ? (width / 2) : width) * _.size(items);
          if (!!controller.max) {
            const scrollTo = controller.scroll + width;
            controller.scroll = scrollTo >= controller.max ? 0 : scrollTo;
            container.scrollLeft = controller.scroll;
          }
        }
        const interval = !!container ? controller.interval : 500;
        controller.timer = setTimeout(($) => {
          controller.playing = false;
          $.playTeaser(true);
        }, interval, this);
        controller.playing = true;
      }else{
        if (controller.timer!=null) {
          clearTimeout(controller.timer);
          controller.timer = null;
        }
        controller.playing = false;
        controller.scroll = 0;
      }
    },
    async open () {
      if (!this.teaser) {
        await Browser.open({ url: this.url });
      }
    },
    select (key) {
      this.$emit('select', key);
    },

    onImgError (type, item) {
      const path = this.controller.imgs[type][item];
      if (_.indexOf(path, this.appURL)<0) {
        this.controller.imgs[type][item] = this.appURL + path;
      }
    }
  },

  beforeDestroy () {
    if (this.teaser) {
      this.playTeaser(false);
    }
  },

  components: {
    BenefitCard: () => import('@/components/BenefitCard'),
  }
};
</script>
